import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: No Encontrado" />
    <section style={{ paddingTop: "16rem" }}>
      <div className="row m-10-hor">
        <div className="col-md-12 text-center">
          <h1>404: No Encontrado</h1>
          <p>
            Vaya, aquí no hay nada...{" "}
            <span role="img" aria-label="pensive-face">
              😔
            </span>
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
